.login{
    background-image: url(../assets/report/login-back.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login .container{
    max-width: 1000px;
    min-height: 100vh;
    align-items: center;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 0 !important;
  }
  .login .container .row{
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.24);
    min-height: 600px;
    width: 100%;
    margin:0 !important;
    align-content: center;
    align-items: center;
    background:#fff !important;
  }
  .login .col-md-7{
    background:#f1ebe4;
    padding: 75px;
    text-align: center;
  }
  .login .col-md-5{
    background:#fff !important;
    padding: 50px;
    text-align: center;
  }
  .login .logo{
    max-width: 70%;
    display: block; 
    margin: 0 auto;
  }
  .login h2{
    color: #222222;
    font-family: sans-serif;
    font-size: 50px;
    font-weight: normal;
    text-transform: capitalize;
    line-height: 1em;
    letter-spacing: 1.2px;
    margin-top:80px;
    margin-bottom:120px;
  }
  .login .social-icon{
    display: inline-block;
  }
  .login .social-icon{
    background:#222222;
    padding:0.4em;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    margin:0 5px
  }
  .login .social-icon svg{
    fill: #ffffff;
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
  }
  .login p{
    text-align: center;
    color:#767676;
    font-size:16px;
    margin-top:20px
  }
  .login .social-icon:hover{
    background:#cd2f27 !important;
    scale: 0.8;
  }
  .login .col-md-5 input{
    background:#f1ebe45c;
    width:100% !important;
    border:0 !important;
    color:#767676;
    min-height: 47px;
    border-radius: 4px;
    border: 1px solid #69727d;
    margin-bottom: 15px;
    padding:10px
  }
  .login form button{
    background:#222222 ;
    border-radius: 5px;
    color:#f1ebe4 ;
    font-size:14px;
    text-transform: uppercase;
    padding: 12px 28px;
    width:100% !important
  }
  .login form a{
    display: block;
    color:#cd2f27 !important;
    margin-top:10px !important;
    font-size:14px
  }