.imei-finder{
    background-image: url(../../assets/report/login-back.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .imei-finder .container{
    max-width: 1000px;
    min-height: 100vh;
    align-items: center;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 0 !important;
    flex-direction: column;
    justify-content: center;
  }
  .imei-finder .container .row{
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.24);
    min-height: 0 !important;
    width: 100%;
    margin:0 !important;
    align-content: center;
    align-items: center;
    background:#fff !important;
    border-radius: 20px;
  }
  .imei-finder .container .row.np{
    box-shadow: none !important;
    min-height: 0 !important;
    width: 100%;
    margin:0 !important;
    align-content: center;
    align-items: center;
    background:#ffffff00 !important;
  }

  .imei-logo{
    max-width: 250px !important;
    display: block; 
    margin: 0 auto;
    margin-bottom:20px !important
  }
  .imei-finder h2 span{
    color:#db1f28 !important;
    font-weight: 700;
  }
  .imei-finder h2{
    color: #222222;
    font-family: sans-serif;
    font-size: 50px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1em;
    letter-spacing: 1.2px;
    margin-top:50px;
    margin-bottom:50px;
    background:#f1ebe4;
    text-align: center;
    padding:20px 0
  }
  .imei-finder .col-md-12{
    padding:0 !important;
  }
  .imei-finder p{
    text-align: center;
    color:#767676;
    font-size:16px;
    margin-top:30px
  }
  .imei-finder form div{
    background:#fff !important;
    border-radius: 50px;
    border:1px solid #000;
    width: auto;
    display: inline-block;
    margin-top:40px
  }
  .imei-finder form div span{
    background:#000 !important;
    border-radius: 50px 0  0 50px;
    color:#fff !important;
    padding:10px 30px;
    display: inline-block;
  }
  .imei-finder form div input{
    background:#fff !important;
    color:#000 !important;
    padding:10px;
    width:400px;
    border-radius:  0 50px 50px 0;
  }
  .imei-finder form button{
    background:#000 ;
    border-radius: 5px;
    color:#f1ebe4 ;
    font-size:20px;
    text-transform: uppercase;
    padding: 10px 40px;
    border-radius: 50px;
    width: auto !important;
    margin-top:20px;
    margin-bottom:50px;
    font-weight: 700;
  }
  .imei-finder form {
    text-align: center;
  }


  .error {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
  }
  