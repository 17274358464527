@font-face {
    font-family: "bilo";
    src: url("../../assets/fonts/bilo/bilo-light.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "bilo";
    src: url("../../assets/fonts/bilo/bilo-regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "bilo";
    src: url("../../assets/fonts/bilo/bilo-medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "bilo";
    src: url("../../assets/fonts/bilo/bilo-bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "bilo";
    src: url("../../assets/fonts/bilo/bilo-extra-bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
  }
  
  body{
    font-family:"bilo";
  }
  ul{
    margin:0 !important
  }
  header {
    background:#e2e8eb;
    padding:30px 50px
  }
  header img{
    max-height:70px;
    width: auto;
  }
  header p{
    color:#07090A;
    margin:0;
    text-align: right;
    font-size:20px;
    font-weight: 700;
  }
  header p span {
    color: #ffffff;
    border: 2px solid #CE2E28;
    font-size: 16px;
    padding: 2px 5px;
    border-radius: 5px;
    background: #CE2E28;
    margin-left: 5px;
  }
  .sec-1{
    background:#07090A;
    padding:30px 0;
    border-bottom:10px solid #CE2E28
  }
  .sec-1 .f-left{
    position: relative;
    background:#f6f5f6;
    padding:20px
  }
  .sec-1 .f-left:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: -3000px;
    background: #f6f5f6;
    z-index: 999;
  }
  .sec-1 ul{
    list-style: none;
    padding-left:0
  }
  .sec-1 li{
    color:#7a7a7a;
    font-size:20px
  }
  .sec-1 .row > .col-md-4:nth-child(1) li{
    font-weight: 800;
  }
  .sec-1 .row > .col-md-5:nth-child(2) li{
    font-weight: 300;
  }
  .sec-1 > .container > .row > .col-md-6:nth-child(2){
    padding-left:50px
  }
  .sec-1 > .container > .row > .col-md-6:nth-child(2) li{
    color:#fff;
    font-weight: 300;
  }
  .sec-1 > .container > .row > .col-md-6:nth-child(2) li strong{
    color:#CE2E28;
    font-weight: 800;
  }
  .sec-2 {
    background:#e2e8eb;
    padding:30px 50px
  }
  .sec-2 .col-md-4 .card-inner{
    background: #fff;
    border-radius: 27px;
    border-top: 7px solid #ce2e28;
    min-height: 572px;
    padding: 40px;
    text-align: center;
  }
  .sec-2 .col-md-4 img{    
    margin: 20px auto !important;
  }
  .sec-2 .col-md-4 h2{
    font-weight: 400;
    font-size:35px;
    margin-bottom: 20px;
  }
  .sec-2 ul{
    list-style: none;
    padding-left:0
  }
  .sec-2 li{
    color:#4D4D4E;
    font-size:20px;
    font-weight: 400;
  }
  .sec-2 .col-md-6 li{
    color:#4D4D4E;
    font-size:20px;
    font-weight: 400;
  }
  .fa-circle-check{
    color:#81C459
  }
  .fa-ban{
    color:#C1272D
  }
  .fa-circle{
    color:#FFE2A8
  }
  .sec-3 {
    background:#e2e8eb;
    padding:30px 50px
  }
  .sec-3 .img-box{
    background:#fff;
    padding:40px;
    border-radius: 27px;
    position: relative;
  
  
  }
  .sec-3 .image-gallery-thumbnail img{
    height: 25% !important;
    width: 100% !important;
    max-width: 100% !important;
    object-fit: cover;   
  }

  .image-gallery-thumbnail{
    height: 20% !important;
    width: 20% !important;
  }

  .image-gallery-thumbnail.active {
    border: 3px solid #C1272D; 
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: inherit !important;    
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: cover !important; 
}
  .sec-4{
    background:#07090A;
  }
  .sec-4 .container{
    padding:30px 50px
  }
  .sec-4 .card-inner{
    background:#fff;
    border-radius: 27px;
    text-align:center;
    padding:40px;
    border-top: 7px solid #CE2E28;
   
  }
  
  .sec-4 .col-md-4 .card-inner{
    
    min-height: 611px;
  }
  .sec-4 h2{
    font-weight: 400;
    font-size:35px;
    margin-bottom: 20px;
  }
  .sec-4 ul{
    list-style: none;
    padding-left:0
  }
  .sec-4 li{
    color:#4D4D4E;
    font-size:20px;
    font-weight: 400;
  }
  .sec-4 .col-md-12 i{
    margin-right:10px
  }
  footer{
    background:#fff
  }
  footer img, footer a {
    display: inline-block;
    vertical-align: middle;
}

  footer h3{
    font-size:35px;
    color:#07090A;
    font-weight: 700;
    margin-bottom:10px
  }
  footer ul{
    list-style: none;
    padding-left:0
  }
  footer li{
    font-size:20px;
    color:#07090A;
    font-weight: 400;
    margin-bottom:10px
  }
  footer li:last-child{
    margin-bottom:0px !important
  }
  footer .col-md-4 img{
    width:20px;
    height:20px;
    margin-right:5px;
  }
  footer .col-md-4 li:last-child img{
    filter:none !important
  }
  footer .col-md-8 .socials img{
    width:40px;
    display:inline-block;
    margin-left:10px;
    filter:brightness(0)
  }
  footer .col-md-8 img.flogo{
    width:250px;
    margin-bottom:20px;
  }
  .footer-bottom{
    background:#07090A !important;
    min-height: 80px;
  }
  
  footer a {
    color: #07090A;
    text-decoration: none;
  }
  .footer-bottom p {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 28px 0;
  }
  
  
  @media (max-width: 768px) {
  
  header * {
      text-align: center !important;
  }
  header p {
      font-size: 16px;
      margin-top: 20px;
  }
  .sec-1 .col-md-4,
  .sec-1 .col-md-5{
  max-width:50% !important
  }
  .sec-1 .col-md-3{
  margin-top:10px
  }
  .sec-1>.container>.row>.col-md-6:nth-child(2) {
      padding-left: 10px !important;
      padding-top: 20px;
  }
  .sec-1>.container>.row>.col-md-6:nth-child(2) .col-md-6{
  max-width:50% !important
  }
  .sec-2,
  .sec-3{
      padding:10px !important;
  }
  .sec-2 .col-md-4 {
      margin-bottom: 20px;
  }
  .sec-3 .img-box{
  margin-bottom: 20px;
  }
  .sec-4 .container{
  padding:10px !important;
  }
  .sec-4 .col-md-6 .col-md-6 {
      max-width: 50% !important;
  }
  .sec-4 .col-md-4 .card-inner {
      margin-bottom: 20px;
  }
  footer * {
      text-align: center;
  }
  footer .text-start {
      text-align: center !important;
  }
  
  header.App-header {
    padding: 30px 20px !important;
  }
  .sec-1 .f-left {
    padding: 20px 10px !important;
  }
  .sec-1 li {
    font-size: 16px;
  }
  .sec-1 .f-left:before{
  display:none
  }
  .sec-2 .col-md-6 li, .sec-2 li {
    text-align: center !important;
  }
  section.sec-4 .col-md-4 .col-md-6 {
    width: 50% !important;
  }
  .image-gallery-thumbnail img {
    width: auto;
    height: 80px; /* Adjust height as necessary */
    object-fit: cover; /* Ensures proper scaling */
}


  }