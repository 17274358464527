.sidebar{
    background:#010000;
    padding:20px 0;
    min-height: 100vh;
}
.sidebar .logo{
    margin: auto;
}
.sidebar .navigation{
    margin-top:30px;
    display: block;
}
.sidebar .navigation .nav-list{
    padding:15px 30px;
    font-size: 18px;
    color:#95979a
}
.sidebar .navigation .nav-list img{
    width:28px; 
    display: inline-block;
    margin-right: 15px;
}
.sidebar .navigation .nav-list.active,
.sidebar .navigation .nav-list:hover{
    background-color:#ce2e28 !important;
    background-image:url('./assets/img/arrow.png');
    background-repeat: no-repeat;
    background-size: 15px auto;
    background-position: 98% center;
}
.sidebar .navigation .nav-list.active img,
.sidebar .navigation .nav-list:hover img{
    filter:brightness(0) invert(1)
}
.sidebar .navigation .nav-list.active a,
.sidebar .navigation .nav-list:hover a{
    color:#fff !important
}
.main{
    padding:50px;
    background: #fff;
}
.main .input-group{
    background:#f8f8f8 !important;
    width: 50% !important;
    border-radius: 50px;
    padding:8px 10px;
    float:left
}
.main .input-group *{
    background:transparent !important;
    border:0 !important;
    border-radius: 0 !important;
}
.main input::placeholder{
    color:#acaeb0 !important
}
.main input{
    outline: none !important;
    border: none !important;
    box-shadow:none !important
}
.main .buttons,
.main .buttons *{
    display: inline-block !important;
}
.main .buttons{
    float:right
}
.main button.red{
    background: #ce2e28 !important;
    color:#fff !important;
    font-size: 20px;
    padding: 8px 25px;
    border-radius: 50px;
}
.main button.black{
    background: #000 !important;
    color:#fff !important;
    font-size: 20px;
    padding: 8px 25px;
    border-radius: 50px;
    margin-left: 10px;
}
.dash-img{
    padding-top:60px;
    display: block;
}
.reports{
    margin-top:30px;
    background:#f8f8f8 !important;
    border-radius: 15px !important;
}
.reports .col-12{
    padding:0 !important
}
.reports *{
    border:0 !important
}
.reports table{
    margin:30px 0 !important
}
.reports thead{
    background:#ededed !important;
}
.reports tr > th:first-child,
.reports tr > td:first-child{
    padding:15px 30px !important;
    text-align: left !important;
}
.reports tr > th,
.reports tr > td{
    text-align: center !important;
    padding:15px;
    vertical-align: middle !important;
}
.reports table,
.reports tr,
.reports th,
.reports td{
    background:transparent !important;
    --bs-table-bg-type:transparent !important;
}
.reports button{
    background-color: #ce2e28 !important;
    border-radius: 50px !important;
    padding:5px 30px
}