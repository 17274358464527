@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* > *{
    font-family: 'Poppins', sans-serif;
}

body{
    background-image: url('./assets/Background.png');
}